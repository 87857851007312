<template>
  <div class="mavonEditor" :id="'markdown-editor-' + uniqueId">
    <mavon-editor
      @imgAdd="imgAdd"
      ref="editor"
      :editable="editable"
      :placeholder="$t('Write something...')"
      @change="onChange"
      :toolbars="markdownOption"
      v-model="text"
      language="en"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MultiSelectInput from "./MultiSelectInput.vue";
import { v4 as uuid } from "uuid";

export default {
  name: "MarkdownEditor",
  emits: ["change", "imageAdded"],
  props: {
    content: {
      type: String,
      default: "",
    },
    builderImages: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
    onPreviewTab: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MultiSelectInput,
  },
  computed: {
    uniqueId() {
      return uuid();
    },
  },
  data() {
    return {
      linkModule: null,
      linkTarget: "",
      linkTypes: [
        {
          key: "Custom",
          value: "custom",
        },
        {
          key: "Content",
          value: "content",
        },
        {
          key: "Page",
          value: "page",
        },
        {
          key: "Form",
          value: "form",
        },
      ],
      linkType: {
        key: "Custom",
        value: "custom",
      },
      linkText: "",
      linkAddress: "",
      toggleLinkModal: false,
      toggleModal: false,
      isClient: false,
      selectedImage: null,
      rects: [],
      successIcon: `<svg version="1.1" id="fi_992650" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.853,256-256S397.167,0,256,0z M256,472.341
			c-119.275,0-216.341-97.046-216.341-216.341S136.725,39.659,256,39.659c119.295,0,216.341,97.046,216.341,216.341
			S375.275,472.341,256,472.341z" style="fill: #4bae4f"></path>
	</g>
</g>
<g>
	<g>
		<path d="M373.451,166.965c-8.071-7.337-20.623-6.762-27.999,1.348L224.491,301.509l-58.438-59.409
			c-7.714-7.813-20.246-7.932-28.039-0.238c-7.813,7.674-7.932,20.226-0.238,28.039l73.151,74.361
			c3.748,3.807,8.824,5.929,14.138,5.929c0.119,0,0.258,0,0.377,0.02c5.473-0.119,10.629-2.459,14.297-6.504l135.059-148.722
			C382.156,186.854,381.561,174.322,373.451,166.965z" style="fill: #4bae4f"></path>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>`,
      warnIcon: `<svg version="1.1" id="fi_159469" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 486.463 486.463" style="enable-background:new 0 0 486.463 486.463;" xml:space="preserve">
<g>
    <g>
        <path d="M243.225,333.382c-13.6,0-25,11.4-25,25s11.4,25,25,25c13.1,0,25-11.4,24.4-24.4
            C268.225,344.682,256.925,333.382,243.225,333.382z" style="fill: rgb(197 218 13)"></path>
        <path d="M474.625,421.982c15.7-27.1,15.8-59.4,0.2-86.4l-156.6-271.2c-15.5-27.3-43.5-43.5-74.9-43.5s-59.4,16.3-74.9,43.4
            l-156.8,271.5c-15.6,27.3-15.5,59.8,0.3,86.9c15.6,26.8,43.5,42.9,74.7,42.9h312.8
            C430.725,465.582,458.825,449.282,474.625,421.982z M440.625,402.382c-8.7,15-24.1,23.9-41.3,23.9h-312.8
            c-17,0-32.3-8.7-40.8-23.4c-8.6-14.9-8.7-32.7-0.1-47.7l156.8-271.4c8.5-14.9,23.7-23.7,40.9-23.7c17.1,0,32.4,8.9,40.9,23.8
            l156.7,271.4C449.325,369.882,449.225,387.482,440.625,402.382z" style="fill: rgb(197 218 13)"></path>
        <path d="M237.025,157.882c-11.9,3.4-19.3,14.2-19.3,27.3c0.6,7.9,1.1,15.9,1.7,23.8c1.7,30.1,3.4,59.6,5.1,89.7
            c0.6,10.2,8.5,17.6,18.7,17.6c10.2,0,18.2-7.9,18.7-18.2c0-6.2,0-11.9,0.6-18.2c1.1-19.3,2.3-38.6,3.4-57.9
            c0.6-12.5,1.7-25,2.3-37.5c0-4.5-0.6-8.5-2.3-12.5C260.825,160.782,248.925,155.082,237.025,157.882z" style="fill: rgb(197 218 13)"></path>
    </g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>`,
      todoIcon: `<svg version="1.1" id="fi_61222" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="510px" height="510px" viewBox="0 0 510 510" style="enable-background:new 0 0 510 510;" xml:space="preserve">
<g>
    <g id="check-circle-outline">
        <path d="M150.45,206.55l-35.7,35.7L229.5,357l255-255l-35.7-35.7L229.5,285.6L150.45,206.55z M459,255c0,112.2-91.8,204-204,204
            S51,367.2,51,255S142.8,51,255,51c20.4,0,38.25,2.55,56.1,7.65l40.801-40.8C321.3,7.65,288.15,0,255,0C114.75,0,0,114.75,0,255
            s114.75,255,255,255s255-114.75,255-255H459z"></path>
    </g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>`,
      infoIcon: `<svg version="1.1" id="fi_471662" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
    <g>
        <path d="M256,0C114.509,0,0,114.496,0,256c0,141.489,114.496,256,256,256c141.491,0,256-114.496,256-256
            C512,114.511,397.504,0,256,0z M256,476.279c-121.462,0-220.279-98.816-220.279-220.279S134.538,35.721,256,35.721
            S476.279,134.537,476.279,256S377.462,476.279,256,476.279z" style="fill: rgb(28, 111, 179);"></path>
    </g>
</g>
<g>
    <g>
        <path d="M256.006,213.397c-15.164,0-25.947,6.404-25.947,15.839v128.386c0,8.088,10.783,16.174,25.947,16.174
            c14.49,0,26.283-8.086,26.283-16.174V229.234C282.289,219.8,270.496,213.397,256.006,213.397z" style="fill: rgb(28, 111, 179);"></path>
    </g>
</g>
<g>
    <g>
        <path d="M256.006,134.208c-15.501,0-27.631,11.12-27.631,23.925c0,12.806,12.131,24.263,27.631,24.263
            c15.164,0,27.296-11.457,27.296-24.263C283.302,145.328,271.169,134.208,256.006,134.208z" style="fill: rgb(28, 111, 179);"></path>
    </g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>`,
      errorIcon: `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M256 0C114.508 0 0 114.497 0 256c0 141.493 114.497 256 256 256 141.492 0 256-114.497 256-256C512 114.507 397.503 0 256 0zm0 472c-119.384 0-216-96.607-216-216 0-119.385 96.607-216 216-216 119.384 0 216 96.607 216 216 0 119.385-96.607 216-216 216z" fill="#d40000" opacity="1" data-original="#000000" class=""></path><path d="M343.586 315.302 284.284 256l59.302-59.302c7.81-7.81 7.811-20.473.001-28.284-7.812-7.811-20.475-7.81-28.284 0L256 227.716l-59.303-59.302c-7.809-7.811-20.474-7.811-28.284 0-7.81 7.811-7.81 20.474.001 28.284L227.716 256l-59.302 59.302c-7.811 7.811-7.812 20.474-.001 28.284 7.813 7.812 20.476 7.809 28.284 0L256 284.284l59.303 59.302c7.808 7.81 20.473 7.811 28.284 0s7.81-20.474-.001-28.284z" fill="#d40000" opacity="1" data-original="#000000" class=""></path></g></svg>`,
      noIcon: `<svg height="384pt" viewBox="0 0 384 384" width="384pt" xmlns="http://www.w3.org/2000/svg" id="fi_1208117"><path d="m192 384c105.863281 0 192-86.128906 192-192 0-51.328125-19.960938-99.550781-56.207031-135.792969-36.25-36.246093-84.464844-56.207031-135.792969-56.207031-105.871094 0-192 86.128906-192 192 0 51.328125 19.960938 99.550781 56.207031 135.792969 36.242188 36.246093 84.464844 56.207031 135.792969 56.207031zm0-32c-37.390625 0-72.800781-12.710938-101.296875-36.078125l225.21875-225.21875c23.367187 28.496094 36.078125 63.90625 36.078125 101.296875 0 88.222656-71.777344 160-160 160zm0-320c37.382812 0 72.800781 12.710938 101.289062 36.078125l-225.210937 225.21875c-23.367187-28.496094-36.078125-63.90625-36.078125-101.296875 0-88.222656 71.777344-160 160-160zm0 0" style="fill: rgb(212, 0, 0);"></path></svg>`,
      criticalIcon: `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M505.403 406.394 295.389 58.102c-8.274-13.721-23.367-22.245-39.39-22.245s-31.116 8.524-39.391 22.246L6.595 406.394c-8.551 14.182-8.804 31.95-.661 46.37 8.145 14.42 23.491 23.378 40.051 23.378h420.028c16.56 0 31.907-8.958 40.052-23.379 8.143-14.421 7.89-32.189-.662-46.369zm-28.364 29.978a12.684 12.684 0 0 1-11.026 6.436H45.985a12.68 12.68 0 0 1-11.025-6.435 12.683 12.683 0 0 1 .181-12.765L245.156 75.316A12.732 12.732 0 0 1 256 69.192c4.41 0 8.565 2.347 10.843 6.124l210.013 348.292a12.677 12.677 0 0 1 .183 12.764z" fill="#ffab00" opacity="1" data-original="#000000" class=""></path><path d="M256.154 173.005c-12.68 0-22.576 6.804-22.576 18.866 0 36.802 4.329 89.686 4.329 126.489.001 9.587 8.352 13.607 18.248 13.607 7.422 0 17.937-4.02 17.937-13.607 0-36.802 4.329-89.686 4.329-126.489 0-12.061-10.205-18.866-22.267-18.866zM256.465 353.306c-13.607 0-23.814 10.824-23.814 23.814 0 12.68 10.206 23.814 23.814 23.814 12.68 0 23.505-11.134 23.505-23.814 0-12.99-10.826-23.814-23.505-23.814z" fill="#ffab00" opacity="1" data-original="#000000" class=""></path></g></svg>`,
      iconEnums: {
        success: "successIcon",
        todo: "todoIcon",
        warn: "warnIcon",
        info: "infoIcon",
        error: "errorIcon",
        no: "noIcon",
        critical: "criticalIcon",
      },
      markdownOption: {
        bold: true,
        italic: true,
        header: true,
        underline: true,
        strikethrough: true,
        mark: true,
        superscript: true,
        subscript: true,
        quote: true,
        ol: true,
        ul: true,
        link: false,
        imagelink: true,
        code: true,
        table: true,
        fullscreen: true,
        readmodel: true,
        htmlcode: true,
        help: true,
        undo: true,
        redo: true,
        trash: true,
        save: true,
        navigation: true,
        alignleft: true,
        aligncenter: true,
        alignright: true,
        subfield: true,
        preview: true,
      },
      text: "",
      images: [],
      selectedImagePosition: null,
      selectedImageName: null,
      unusedImages: new Set(),
    };
  },
  destroyed() {
    try {
      const linkIcon = document.querySelector(
        `#markdown-editor-${this.uniqueId} ri-link`
      );
      linkIcon.removeEventListener("click", this.clickLink);
    } catch (e) {
      console.log(e);
    }
  },
  async mounted() {
    await this.$nextTick();
    // this.isClient = true;
    try {
      const tableIcon = document.querySelector(
        `#markdown-editor-${this.uniqueId} .fa-mavon-table`
      );
      const linkIcon = document.createElement("i");
      linkIcon.classList.add("ri-link");
      linkIcon.classList.add("cursor-pointer");
      linkIcon.classList.add("op-icon");
      linkIcon.title = this.$t("link");
      linkIcon.addEventListener("click", this.clickLink);
      tableIcon.parentNode.insertBefore(linkIcon, tableIcon.nextSibling);
    } catch (e) {
      console.log(e);
    }
    this.text = this.content;
    this.images = [...this.builderImages];
    await this.$nextTick();
    this.renderImages();
  },
  watch: {
    async content(val) {
      this.text = val;
      if (!this.images.length) this.images = [...this.builderImages];
      await this.$nextTick();
      this.renderImages(this.text);
    },
  },
  methods: {
    clickLink() {
      this.toggleLinkModal = true;
    },
    cancel() {
      this.toggleLinkModal = false;
    },
    insertTextAtCursor(inputElement, textToInsert) {
      // Get the current cursor position
      const cursorPosition = inputElement.selectionStart;

      // Get the text before and after the cursor position
      const textBeforeCursor = inputElement.value.substring(0, cursorPosition);
      const textAfterCursor = inputElement.value.substring(cursorPosition);

      // Combine the text before and after the cursor with the text to insert
      const newTextValue = textBeforeCursor + textToInsert + textAfterCursor;

      // Update the value of the input field with the new text
      inputElement.value = newTextValue;

      // Set the cursor position after the inserted text
      const newCursorPosition = cursorPosition + textToInsert.length;
      inputElement.setSelectionRange(newCursorPosition, newCursorPosition);

      // Trigger the input event in case there are event listeners relying on it
      const inputEvent = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      inputElement.dispatchEvent(inputEvent);
    },
    /**
     * converts regular string to kebab case
     * @param {originalString} string to be converted
     */
    kebabCase(originalString) {
      try {
        return originalString
          .toLowerCase()
          .replace(/\s+/g, "-") // Replace spaces with dashes
          .replace(/[^a-z0-9-]/g, "");
      } catch (e) {
        return "";
      }
    },
    addLink() {
      const runtimeConfig = useRuntimeConfig();
      const textArea = document.querySelector(
        `#markdown-editor-${this.uniqueId} .auto-textarea-input`
      );
      let address = "";
      if (this.linkType?.value === "custom") {
        address = this.linkAddress;
      } else if (this.linkType?.value === "form") {
        address = `${process.env.frontendOrigin}/form/${this.kebabCase(
          this.linkModule?.name
        )}?id=${this.linkModule?.id}`;
      } else if (this.linkType?.value === "content") {
        address = `${process.env.frontendOrigin}/content/${this.kebabCase(
          this.linkModule?.name
        )}?id=${this.linkModule?.id}`;
      } else if (this.linkType?.value === "page") {
        address = `${process.env.frontendOrigin}/navigation/${this.kebabCase(
          this.linkModule?.name
        )}?id=${this.linkModule?.id}`;
      }
      this.insertTextAtCursor(
        textArea,
        `[${this.linkText}](${address}${
          this.linkTarget ? "?target=_blank" : ""
        })`
      );
      this.toggleLinkModal = false;
    },
    rectsChanged(rects) {
      const imageIndex = this.images.findIndex(
        (image) =>
          image.position == this.selectedImagePosition &&
          image.name === this.selectedImageName
      );
      if (imageIndex > -1) {
        this.images[imageIndex].rects = [...rects];
      }
    },
    addListenerToImages(domImage, position) {
      domImage.setAttribute("usemap", `#workmap-${position}`);
      domImage.addEventListener("contextmenu", async (e) => {
        const imageIndex = this.images.findIndex(
          (image) => image.position == position
        );
        e.preventDefault();
        this.selectedImage = domImage;
        this.rects = this.images?.[imageIndex]?.rects ?? [];
        this.selectedImagePosition = position;
        this.selectedImageName = name;
        this.toggleModal = true;
        await this.$nextTick();
        this.$refs["image-mapper"].createCanvas();
      });
    },
    /**
     *  modifies image name to sync with markdown image name
     * @param {text} image name
     */
    sanitizeImageName(text) {
      return text.match(/\[(.*?)\]/)?.[1] ?? "";
    },
    renderImages(text) {
      this.images.forEach(async (image, index) => {
        try {
          /**
           * extract image markdown string from the markdown text
           */
          const imageMarkdown = this.extractImageMarkdown(
            text,
            image.name,
            image.position
          );
          let imageName = image.name;
          // if the markdown was extracted we update the image name
          if (!!imageMarkdown?.length) {
            imageName = this.sanitizeImageName(imageMarkdown?.[0] ?? "");
          }
          let img =
            document.querySelectorAll(
              `#markdown-editor-${this.uniqueId} img[alt='${imageName}'][src='${image.position}']`
            )?.[this.onPreviewTab ? 1 : 0] ??
            document.querySelectorAll(
              `#markdown-editor-${this.uniqueId} img[alt='${imageName}'][src='${image.position}']`
            )?.[0];

          if (!img) {
            img =
              document.querySelectorAll(
                `#markdown-editor-${this.uniqueId} img[alt='${imageName}'][rel='${image.position}']`
              )?.[this.onPreviewTab ? 1 : 0] ??
              document.querySelectorAll(
                `#markdown-editor-${this.uniqueId} img[alt='${imageName}'][rel='${image.position}']`
              )?.[0];
          }

          if (img) {
            if (this.unusedImages.has(index)) {
              this.unusedImages.delete(index);
            }
            // if markdown exists for the image then we extract and update image dimensions with custom dimensions
            if (!!imageMarkdown?.length) {
              const dimensions = this.extractImageDimensions(
                imageMarkdown?.[0] ?? ""
              );
              if (dimensions) {
                img.setAttribute("height", dimensions?.height ?? "");
                img.setAttribute("width", dimensions?.width ?? "");
              }
            }
            img.src = image.base64;
            this.addListenerToImages(img, image.position, image.name);
            const rects = image?.rects ?? [];
            let map =
              document.querySelectorAll(
                `#markdown-editor-${this.uniqueId} map[name='workmap-${image.position}']`
              )?.[this.onPreviewTab ? 1 : 0] ??
              document.querySelectorAll(
                `#markdown-editor-${this.uniqueId} map[name='workmap-${image.position}']`
              )?.[0];
            if (!map) {
              map = document.createElement("map");
              map.setAttribute("name", `workmap-${image.position}`);
            }
            map.innerHTML = "";
            for (let i = 0; i < rects.length; i++) {
              const savedRect = rects[i];
              let area = document.createElement("area");
              area.setAttribute("id", "area-1");
              area.setAttribute("shape", "rect");
              area.setAttribute(
                "coords",
                `${savedRect.left},${savedRect.top},${
                  savedRect.left + savedRect.width
                },${savedRect.top + savedRect.height}`
              );
              area.setAttribute("href", "javascript:void(0)");
              const enums = {
                content: "content-builder",
                form: "form-builder",
                page: "page-builder",
              };
              area.addEventListener("click", () => {
                if (!!savedRect?.module?.id) {
                  this.$router.push(
                    `/${enums[savedRect?.type?.value]}/${
                      savedRect?.module?.id
                    }/edit`
                  );
                }
              });
              map.appendChild(area);
            }
            img.parentNode.insertBefore(map, img.nextSibling);
            await this.$nextTick();
            if (!img.classList.contains("maphilighted")) {
              try {
                $(() => {
                  $(img).maphilight();
                });
              } catch (e) {
                console.log(e);
              }
            }
            // add class necessary for correctly rendering the updated image with custom dimensions
            if (img?.parentNode?.children?.[0])
              img.parentNode.children[0].classList.add("custom-dimensions");
          } else {
            this.unusedImages.add(index);
          }
        } catch (e) {
          console.log(e);
        }
      });
    },
    /**
     * extracts the image markdown from the markdown text based on image name and position
     * @param text main markdown text
     * @param name image name
     * @param position image position
     */
    extractImageMarkdown(text, name, position) {
      try {
        const regex = new RegExp(
          `!\\[${name}(?:\\s*=\\s*\\S+)?\\]\\(${position}\\)`,
          "g"
        );

        const matches = text.match(regex);

        if (typeof matches == "object") {
          return matches;
        } else {
          return [];
        }
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    extractImageDimensions(text) {
      try {
        const regex = /=\s*(\d+)x(\d+)/;

        const match = text.match(regex);

        if (match) {
          const height = match[1];
          const width = match[2];
          return { width: width, height: height };
        } else {
          return null;
        }
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async onChange(text) {
      try {
        await this.$nextTick();
        const markdownIt = this.$refs.editor.markdownIt;

        markdownIt.renderer.rules.link_open = function (
          tokens,
          idx,
          options,
          env,
          self
        ) {
          const targetHref = tokens[idx]?.attrs?.[0]?.[1] ?? "";
          const regex = /[?&]target=([^&#]*)/;
          const match = targetHref.match(regex);
          const target = match ? match[1] : null;
          tokens[idx].attrSet("target", target === "_blank" ? "_blank" : "");
          return self.renderToken(tokens, idx, options);
        };

        const tokens = text.split("\n\n");

        let isModified = false;

        tokens.forEach(async (token) => {
          const accordionPattern = /^\s*> \[![^\]]+\]- [\s\S]*$/;
          if (accordionPattern.test(token)) {
            let modifiedToken = `-${token.trim()}`;
            let customTablePattern = /^\s*>\s*\|.*\|.*\|.*$/gm;
            modifiedToken = modifiedToken.replace(
              customTablePattern,
              function (match) {
                return match.replace(/^\s*>\s*/, "");
              }
            );

            let renderedMarkdown = markdownIt.render(modifiedToken);
            let markdownContainer =
              document.querySelectorAll(
                `#markdown-editor-${this.uniqueId} .v-show-content`
              )?.[this.onPreviewTab ? 1 : 0] ??
              document.querySelectorAll(
                `#markdown-editor-${this.uniqueId} .v-show-content`
              )?.[0];
            const pattern = /-> \[![^\]]+]- (.+)/;
            let match = modifiedToken.match(pattern);

            const iconPattern = /> \[!([^[\]\-]*)\]/;
            const iconText = modifiedToken.match(iconPattern);

            let icon = "";

            if (iconText && iconText[1]) {
              icon = iconText[1].trim();
            }

            let extractedText = "";
            if (match) {
              extractedText = match[1];
            }
            if (markdownContainer.innerHTML.trim() === "") {
              markdownContainer.innerHTML = renderedMarkdown;
            } else {
              if (!isModified) {
                markdownContainer.innerHTML = "";
                isModified = true;
              }
              const child = document.createElement("div");
              child.classList.add("accordion-container");
              child.classList.add(icon);
              child.innerHTML = `<button class="accordion">${
                this?.[this.iconEnums[icon]] ?? ""
              }${extractedText} ></button>
                                    <div class="panel">
                                    ${renderedMarkdown}
                                    </div>`;
              let firstParagraph = child.querySelector("p");
              if (firstParagraph) {
                let inputString = firstParagraph.outerHTML;
                let regex = /<p>.*?<br>/;
                let hasBrTag = regex.test(inputString);
                let result = hasBrTag ? inputString.replace(regex, "") : "";

                const newParagraph = document.createElement("p");
                newParagraph.innerHTML = result;
                firstParagraph.replaceWith(newParagraph);
              }
              markdownContainer.appendChild(child);
            }
          } else {
            let renderedMarkdown = markdownIt.render(token);
            let markdownContainer =
              document.querySelectorAll(
                `#markdown-editor-${this.uniqueId} .v-show-content`
              )?.[this.onPreviewTab ? 1 : 0] ??
              document.querySelectorAll(
                `#markdown-editor-${this.uniqueId} .v-show-content`
              )?.[0];
            if (!isModified) {
              markdownContainer.innerHTML = "";
              isModified = true;
            }
            const child = document.createElement("div");
            child.innerHTML = renderedMarkdown;
            markdownContainer.appendChild(child);
          }
        });

        let acc = document.querySelectorAll(
          `#markdown-editor-${this.uniqueId} .accordion`
        );
        let i;

        for (i = 0; i < acc.length; i++) {
          acc[i].addEventListener("click", function () {
            this.classList.toggle("active");
            let panel = this.nextElementSibling;
            if (panel.style.display === "block") {
              panel.style.display = "none";
            } else {
              panel.style.display = "block";
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
      await this.$nextTick();
      this.renderImages(text);
      this.$emit("change", text);
    },
    async imgAdd(pos, $file) {
      try {
        this.images = [
          ...this.images,
          {
            position: pos,
            name: $file._name.replace(/\(([^)]*)\)/g, "$1").trim(),
            base64: $file.miniurl,
            rects: [],
          },
        ];
        await this.$nextTick();
        const image = document.querySelector(
          `#markdown-editor-${this.uniqueId} img[alt='${$file._name
            .replace(/\(([^)]*)\)/g, "$1")
            .trim()}']`
        );
        this.addListenerToImages(image, pos, $file._name);
        this.$emit("imageAdded", this.images);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.mavonEditor {
  min-width: 100%;
  min-height: 100%;
}

:deep(.markdown-body) {
  height: 100vh;
}

:deep(.v-note-wrapper) {
  z-index: 1 !important;
}

/* Style the buttons that are used to open and close the accordion panel */
:deep(.v-note-show) .accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s;
}

:deep(.v-note-show) .error .accordion {
  background: rgba(212, 0, 0, 0.2);
  color: rgb(212, 0, 0);
}

:deep(.v-note-show) .error .accordion:hover {
  background: rgba(212, 0, 0, 0.3);
}

:deep(.v-note-show) .error .active {
  background: rgba(212, 0, 0, 0.3);
}

:deep(.v-note-show) .success .accordion {
  background: rgba(75, 174, 79, 0.2);
  color: rgb(75, 174, 79);
}

:deep(.v-note-show) .success .accordion:hover {
  background: rgba(75, 174, 79, 0.3);
}

:deep(.v-note-show) .success .active {
  background: rgba(75, 174, 79, 0.3);
}

:deep(.v-note-show) .warn .accordion {
  background: rgba(197, 218, 13, 0.2);
  color: rgb(197, 218, 13);
}

:deep(.v-note-show) .warn .accordion:hover {
  background: rgba(233, 255, 43, 0.3);
}

:deep(.v-note-show) .warn .active {
  background: rgba(233, 255, 43, 0.3);
}

:deep(.v-note-show) .critical .accordion {
  background: rgba(255, 171, 0, 0.2);
  color: rgb(255, 171, 0);
}

:deep(.v-note-show) .critical .accordion:hover {
  background: rgba(255, 171, 0, 0.3);
}

:deep(.v-note-show) .critical .active {
  background: rgba(255, 171, 0, 0.3);
}

:deep(.v-note-show) .no .accordion {
  background: rgba(212, 0, 0, 0.2);
  color: rgb(212, 0, 0);
}

:deep(.v-note-show) .no .accordion:hover {
  background: rgba(212, 0, 0, 0.3);
}

:deep(.v-note-show) .no .active {
  background: rgba(212, 0, 0, 0.3);
}

:deep(.v-note-show) .todo .accordion {
  background: rgba(0, 0, 0, 0.2);
  color: rgb(0, 0, 0);
}

:deep(.v-note-show) .todo .accordion:hover {
  background: rgba(0, 0, 0, 0.3);
}

:deep(.v-note-show) .todo .active {
  background: rgba(0, 0, 0, 0.3);
}

:deep(.v-note-show) .info .accordion {
  background: rgba(28, 111, 179, 0.2);
  color: rgb(28, 111, 179);
}

:deep(.v-note-show) .info .accordion:hover {
  background: rgba(28, 111, 179, 0.3);
}

:deep(.v-note-show) .info .active {
  background: rgba(28, 111, 179, 0.3);
}

:deep(.v-note-show) .accordion-container {
  margin-bottom: 1rem;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
:deep(.v-note-show) .active,
.accordion:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
:deep(.v-note-show) .panel {
  background-color: white;
  display: none;
  overflow: auto;
}

:deep(.markdown-body) table {
  display: inline-table;
}

:deep(svg) {
  fill: black;
  margin-right: 5px;
  height: 20px;
  width: 20px;
  position: relative;
  bottom: 2px;
}

.image-map-area {
  background-color: white;
}

.image-map-area:hover {
  background-color: white;
}

:deep(.add-image-link-wrapper) {
  display: none;
}

:deep(.custom-dimensions) {
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
</style>
