<script>
export default {
  emits: ["remove"],
  props: {
    commentFiles: {
      type: Array,
      default: [],
    },
    showImages: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    deleteUpload(index) {
      this.$emit("remove", index);
    },
  },
};
</script>
<template>
  <div class="row mt-3" v-if="commentFiles && commentFiles.length > 0">
    <div
      class="col-lg-6 col-md-4"
      v-for="(file, index) in commentFiles"
      :key="index"
    >
      <div class="card mb-1 shadow-none border">
        <div class="p-2">
          <div class="row flex align-items-center">
            <!-- <div class="col-auto">
              <div class="avatar-sm" v-if="file.type === 'png'">
                <img
                  v-if="showImages"
                  class="w-100"
                  :src="'data:image\/png;base64,' + file.base64"
                  alt=""
                />
                <img v-else class="w-100" alt="" />
              </div>
              <div class="avatar-sm" v-else-if="file.type === 'pdf'">
                <img class="w-100" alt="" />
              </div>
              <div class="avatar-sm" v-else-if="file.type === 'csv'">
                <img class="w-100" alt="" />
              </div>
              <div class="avatar-sm" v-else-if="file.type === 'jpg'">
                <img
                  v-if="showImages"
                  class="w-100"
                  :src="'data:image\/jpeg;base64,' + file.base64"
                  alt=""
                />
                <img
                  v-else
                  class="w-100"
                  src="../../assets/images/icons/jpg.png"
                  alt=""
                />
              </div>
              <div class="avatar-sm" v-else-if="file.type === 'jpeg'">
                <img
                  class="w-100"
                  src="../../assets/images/icons/jpeg.png"
                  alt=""
                />
              </div>
              <div class="avatar-sm" v-else-if="file.type === 'doc'">
                <img
                  class="w-100"
                  src="../../assets/images/icons/docs.png"
                  alt=""
                />
              </div>
              <div class="avatar-sm" v-else-if="file.type === 'docx'">
                <img
                  class="w-100"
                  src="../../assets/images/icons/docx.png"
                  alt=""
                />
              </div>
              <div class="avatar-sm" v-else-if="file.type === 'ppt'">
                <img
                  class="w-100"
                  src="../../assets/images/icons/ppt.png"
                  alt=""
                />
              </div>
              <div class="avatar-sm" v-else-if="file.type === 'pptx'">
                <img
                  class="w-100"
                  src="../../assets/images/icons/pptx.png"
                  alt=""
                />
              </div>
              <div class="avatar-sm" v-else-if="file.type === 'xls'">
                <img
                  class="w-100"
                  src="../../assets/images/icons/xls.png"
                  alt=""
                />
              </div>
              <div class="avatar-sm" v-else-if="file.type === 'xlsx'">
                <img
                  class="w-100"
                  src="../../assets/images/icons/xlsx.png"
                  alt=""
                />
              </div>
            </div> -->
            <div class="col ps-0">
              <a
                href="javascript:void(0);"
                class="text-white font-weight-semibold"
                >{{ file.name || "no file name" }}</a
              >
              <p class="mb-0">{{ file.size || "MB" }}</p>
            </div>
            <div class="col-auto">
              <a
                @click="deleteUpload(index)"
                class="btn btn-link btn-lg text-danger"
              >
                <feather-icon size="16" icon="TrashIcon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
