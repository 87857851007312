<template>
  <b-button
    :class="disabled ? 'disable-loading-button' : ''"
    :disabled="loading"
    class="d-flex align-items-center"
    variant="primary"
    @click="$emit('click')"
  >
    <div v-if="loading" class="btn-spinner mr-1" />
    <slot />
  </b-button>
</template>

<script>
export default {
  emits: ["click"],
  props: {
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.disable-loading-button {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-spinner,
.btn-spinner:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
}

.btn-spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid white;
  border-right: 0.2em solid white;
  border-bottom: 0.2em solid white;
  border-left: 0.2em solid transparent;
  transform: translateZ(0);
  animation: spinning 1s infinite linear;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
